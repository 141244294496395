<template>
  <b-row
    v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
    class="content-header"
  >
    <!-- Content Left -->
    <b-col
      class="content-header-left mb-2"
      cols="12"
      md="9"
    >
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <h2 class="content-header-title float-left pr-1 mb-0">
            {{ $t($route.meta.pageTitle) }}
          </h2>
          <div class="breadcrumb-wrapper">
            <b-breadcrumb class="breadcrumb-slash">
              <b-breadcrumb-item :to="`/${this.$i18n.locale}`">
                <feather-icon
                  icon="HomeIcon"
                  size="16"
                  class="align-text-top"
                />
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-for="item in $route.meta.breadcrumb"
                :key="item.text"
                :active="item.active"
                :to="item.to"
              >
                {{ $t(item.text) }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>
    <b-col cols="12">
      <div
        class="alert alert-danger"
        style="padding: 15px;"
      >
        <h3>اطلاعیه مهم به مشتریان گرامی صرافی بیتصرف</h3>
        <hr>
        <p style="padding-bottom:15px;text-align: justify;">
          با سلام و احترام،
        </p>
        <p style="padding-bottom:15px;text-align: justify;">
          بدین‌وسیله به اطلاع می‌رساند که صرافی رمزارز بیتصرف، از تاریخ 23 آذر ماه دیگر از رمز ارز داریک
          پشتیبانی نخواهد کرد.
        </p>
        <p style="padding-bottom:15px;text-align: justify;">
          از شما مشتریان گرامی خواهشمندیم تا پیش از این تاریخ، رمز ارزهای داریک خود را از حساب‌های صرافی
          برداشت نمایید. شایان ذکر است که پس از تاریخ مذکور، داریک از صرافی حذف خواهد شد و امکان دسترسی یا
          معامله آن وجود نخواهد داشت.
        </p>
        <p style="padding-bottom:15px; color:#007a51;text-align: justify;"> صرافی بیتصرف در راستای بهبود
          خدمات، برنامه‌های جدید و متمایزی برای سایر ارزهای دیجیتال ارائه خواهد
          کرد.
          از همراهی شما سپاسگزاریم و در صورت نیاز به اطلاعات بیشتر، لطفاً با پشتیبانی ما تماس حاصل
          فرمایید.</p>
        <p style="padding-bottom:15px;text-align: justify;">
          با احترام،<br>
          صرافی رمزارز بیتصرف
        </p>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BBreadcrumb, BBreadcrumbItem, BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
  },
}
</script>
